export const FirebaseConfig = {
	"projectId": "taxii-592ca",
	"appId": "1:762722217472:web:790db1291c5ea98b23f2e5",
	"databaseURL": "https://taxii-592ca-default-rtdb.firebaseio.com",
	"storageBucket": "taxii-592ca.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyAFnIyu3Pz-amFmUidow3c0_1Xkpy3n6EY",
	"authDomain": "taxii-592ca.firebaseapp.com",
	"messagingSenderId": "762722217472",
	"measurementId": "G-YPWYG6VD99"
};